

var addresses =
[
  {
    "name": "Piétrebais (Cabinet)",
    "address": "9 Ruelle Minsart, 1315 Piétrebais (commune d'Incourt, à coté de Grez-Doiceau et Wavre)",
    "phone": "0495/38.92.63",
    "website": "",
    "geolocation": {
      "lat": 50.728180,
      "lng": 4.752724
    }
  },
  {
    "name": "Enghien",
    "address": "Centre vétérinaire Animalliance, 49 rue des écoles, 7850 Enghien (tous les lundi)",
    "phone": "02/395 32 22",
    "website": "centreveterinaire-animalliance.com",
    geolocation: {
      "lat": 50.696349,
      "lng": 4.040923,
    }
  },
  {
    "name": "Watermal Boisfort",
    "address": "Dr Crucq, 1, Place Joseph Wauters, 1170 Watermal Boisfort (une fois par mois).",
    "phone": "0475/91 61 22",
    "website": "veterinaire-coralie-crucq.be.",
    geolocation: {
      "lat": 50.807992,
      "lng": 4.420060,
    }
  },
  {
    "name": "Braine-le-Château",
    "address": "Dr Magin et Dr Ewalenko, 61a Rue de Tubize, 1440 Braine-le-Château (une fois par mois)",
    "phone": "02/390 02 30",
    "website": "veterinaire-tubize-braine.be.",
    geolocation: {
      "lat": 50.681594,
      "lng": 4.256150,
    }
  },
  {
    "name": "Tubize",
    "address": "Dr Magin et Dr Ewalenko, 61a Rue de Tubize, 1440 Braine-le-Château (une fois par mois)",
    "phone": "02/390 02 30",
    "website": "veterinaire-tubize-braine.be.",
    geolocation: {
      "lat": 50.681594,
      "lng": 4.256150,
    }
  },
  {
    "name": "Ecaussine d’Enghien",
    "address": "Dr Marchand, 36a rue St Roch, 7190 Ecaussine d’Enghien. (une fois par mois)",
    "phone": "067/44 47 12",
    "website": "",
    geolocation: {
      "lat": 50.573546,
      "lng": 4.167023,
    }
  }
]
