// init foundation
$(document).foundation();

/**
 	* quick fix for the slider height when resize occur
	*/
	function winResizeOrbitHandler () {
		// set up a ratio and get a val in pixel
		// to manually set the height
		var height = document
			.querySelector('.carrousel')
			.getBoundingClientRect().width * 0.32
		// set the height of the container
		$('.orbit-container').css({ height: height })
		// set the height of the slide
		$('li.orbit-slide')
			.css({ 'max-height': height, 'min-height': height })
	}

	// throttle ~ every 10 frames (== ~ 6 redimensions/s)
	$(window)
		.resize(
			Foundation.util.throttle(winResizeOrbitHandler,  166)
		)

	$('body').ready(function() {
			// execute 'on load'
			winResizeOrbitHandler()
	})



