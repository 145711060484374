

// create main closure (gmaps callback)
function initMap() {

  var map;
  var mapStyle = [
      {
          "featureType": "administrative.country",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#890a4a"
              }
          ]
      },
      {
          "featureType": "administrative.province",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#890a4a"
              }
          ]
      },
      {
          "featureType": "administrative.locality",
          "elementType": "all",
          "stylers": [
              {
                  "hue": "#2c2e33"
              },
              {
                  "saturation": 7
              },
              {
                  "lightness": 19
              },
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "administrative.locality",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#890a4a"
              }
          ]
      },
      {
          "featureType": "administrative.neighborhood",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#890a4a"
              }
          ]
      },
      {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#890a4a"
              }
          ]
      },
      {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [
              {
                  "hue": "#ffffff"
              },
              {
                  "saturation": -100
              },
              {
                  "lightness": 100
              },
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "landscape.man_made",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#890a4a"
              }
          ]
      },
      {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
              {
                  "hue": "#ffffff"
              },
              {
                  "saturation": -100
              },
              {
                  "lightness": 100
              },
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
              {
                  "hue": "#bbc0c4"
              },
              {
                  "saturation": -93
              },
              {
                  "lightness": 31
              },
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "road",
          "elementType": "labels",
          "stylers": [
              {
                  "hue": "#bbc0c4"
              },
              {
                  "saturation": -93
              },
              {
                  "lightness": 31
              },
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#decd4d"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
              {
                  "color": "#decd4d"
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#890a4a"
              }
          ]
      },
      {
          "featureType": "road.highway.controlled_access",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#890a4a"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#eeedac"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "labels",
          "stylers": [
              {
                  "hue": "#bbc0c4"
              },
              {
                  "saturation": -93
              },
              {
                  "lightness": -2
              },
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#890a4a"
              }
          ]
      },
      {
          "featureType": "road.local",
          "elementType": "geometry",
          "stylers": [
              {
                  "hue": "#e9ebed"
              },
              {
                  "saturation": -90
              },
              {
                  "lightness": -8
              },
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#890a4a"
              }
          ]
      },
      {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [
              {
                  "hue": "#e9ebed"
              },
              {
                  "saturation": 10
              },
              {
                  "lightness": 69
              },
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
              {
                  "hue": "#e9ebed"
              },
              {
                  "saturation": -78
              },
              {
                  "lightness": 67
              },
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "color": "#85cded"
              }
          ]
      }
  ]
  var bounds = new google.maps.LatLngBounds()

  function initGMap() {
    var myMapCenterLatLng = {lat: 50.7175, lng: 4.398148}
    map = new google.maps.Map(document.getElementById('carte'), {
     center: myMapCenterLatLng,
     zoom: 10,
     scrollwheel: false,
     styles: mapStyle
    });



    var infowindow
    function addMarker(latLng, info) {
      var marker = new google.maps.Marker({
         position: latLng,
         map: map,
         title: 'Hello World!'
       });

       marker.addListener('click', function() {
        if (infowindow) {
           infowindow.close();
        }
        infowindow = addInfowindow(info)
        infowindow.open(map, marker);
      });

      // this will allow gmap 'autocentering'
      registerMarkerBounds(marker)
    }


    function addInfowindow(info) {
      var infowindow = new google.maps.InfoWindow({
        content: info
      });
      return infowindow
    }

    function registerMarkerBounds(marker) {
      bounds.extend(marker.getPosition())
    }

    addresses.forEach(function(address) {
      addMarker(address.geolocation, address.address)
    })
    // Once all markers are registered,
    // call map.fitBounds(bounds)
    // this takes care of gmap 'autocentering'
    // map.fitBounds(bounds)

  }
  initGMap();
} // end of main closures


(function() {
  // programatically load the gmaps api script
  function addScript(src) {
    var script = document.createElement('script')
    script.async = true
    script.defer = true
    script.src = src
    document.querySelector('head').appendChild(script)
  }

  var gMapsBaseUrl = 'https://maps.googleapis.com/maps/api/js'
  // var gMapsAPIKEY = 'key=APIKEY'
  var gMapsAPIKEY = 'key=AIzaSyCu6IW0QK4mUf60aMXYZ-0h40xCH6uZr8I'
  var gMapsUrlCallback = 'callback=initMap'
  var gMapsURL =
  gMapsBaseUrl + '?' + gMapsAPIKEY + '&' + gMapsUrlCallback
  addScript(gMapsURL)
})()
